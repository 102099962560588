.App {
  text-align: center;
  background-image: url("https://res.cloudinary.com/dvloxectq/image/upload/c_scale,w_1920/q_auto/f_webp/Multicook/background_and_pattern_rimcqh");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  padding: 0;
}

.contacts {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 80%;
  padding-top: 20px;
  margin: 0 auto -10px auto;
  font-weight: 700;
}
.address,
.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  font-size: 20px;
}
.social {
  flex-direction: row;
}
.fb {
  font-size: 31px;
  color: #1877f2;
}
.ig {
  font-size: 32px;
  color: #cd486b;
}
.ml {
  font-size: 24px;
}
.fb,
.ig,
.ml {
  margin: 0;
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-primary);
  border: 1px solid var(--main-red-color);
  width: 6vh;
  height: 6vh;
  margin: 0 20px 0 20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -moz-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.link:hover {
  background-color: var(--blured-white-color);
  transform: scale(1.1);
}
.hero-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.hero-logo {
  width: 100%;
}
.announce {
  font-weight: 700;
  margin: 20px;
  font-size: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.announce-image {
  width: 80%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.coming-soon {
  font-family: var(--headings-font-family);
  color: var(--main-red-color);
  letter-spacing: 10px;
  word-spacing: 40px;
  width: 100%;
  transform: rotate(-30deg);
  font-size: 6vw;
  position: absolute;
  top: 36%;
  text-shadow: 1px 1px 2px #000, -0.5px -0.5px 0.5px #fff;
}

.menu-section {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 80%;
}
.section-header {
  font-family: var(--headings-font-family);
  color: var(--main-red-color);
  font-size: var(--font-size-heading);
  line-height: normal;
  font-weight: var(--font-weight-headings);
  margin: 16px;
}
.delivery-header {
  color: var(--main-black-color);
}
.phone {
  font-size: 36px;
}
.phone-link {
  display: flex;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}
.phone-icon {
  font-size: 40px;
  color: var(--main-red-color);
  animation-name: ring;
  -webkit-animation-name: ring;
  animation-duration: 14s;
  -webkit-animation-duration: 14s;
  animation-delay: 2s;
  -webkit-animation-delay: 2s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1.5% {
    transform: rotate(20deg);
  }
  3% {
    transform: rotate(-18deg);
  }
  4.5% {
    transform: rotate(24deg);
  }
  6% {
    transform: rotate(-22deg);
  }
  7.5% {
    transform: rotate(20deg);
  }
  9% {
    transform: rotate(-18deg);
  }
  10.5% {
    transform: rotate(16deg);
  }
  12% {
    transform: rotate(-14deg);
  }
  13.5% {
    transform: rotate(12deg);
  }
  15% {
    transform: rotate(-10deg);
  }
  16.5% {
    transform: rotate(8deg);
  }
  18% {
    transform: rotate(-6deg);
  }
  19.5% {
    transform: rotate(4deg);
  }
  21% {
    transform: rotate(-2deg);
  }
  22.5% {
    transform: rotate(2deg);
  }
  24% {
    transform: rotate(-1deg);
  }
  25.5% {
    transform: rotate(1deg);
  }
  27% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.note {
  font-size: 16px;
  vertical-align: super;
}
.delivery-detiles {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.detiles-section {
  font-size: 20px;
  padding: 0 10px;
}
.asterix {
  font-size: 20px;
  font-weight: 700;
}
.input:focus + .required {
  display: none;
}
.product {
  background-color: var(--main-black-color);
  color: var(--main-white-color);
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--border-radius-primary);
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  height: 380px;
  max-width: 310px;
  box-sizing: border-box;
  position: relative;
}
.vegan-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 36px;
  color: var(--main-green-color);
}
.product-name {
  font-size: var(--font-size-name);
  line-height: normal;
}
.ingredients-wraper {
  flex-grow: 1;
  display: flex;
  align-items: end;
}
.product-ingredients {
  border: 1px, solid, var(--main-red-color);
  border-radius: var(--border-radius-secondary);
  margin: 0 20px 0 20px;
  padding: 8px;
  font-size: 12px;
  color: var(--blured-white-color);
}
.image-wraper {
  height: 56%;
  border-radius: 20px;
}
.menu-image {
  border-top-left-radius: var(--border-radius-primary);
  border-top-right-radius: var(--border-radius-primary);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes wipe-enter {
  0% {
    transform: scale(0.001, 0);
  }
  50% {
    transform: scale(0.001, 1);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .visible {
    width: 80%;
    height: fit-content;
    animation-name: wipe-enter;
    animation-duration: 1s;
    animation-iteration-count: 1;
  }
}

.devider-container {
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.devider-center {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.devider-icon {
  color: var(--main-red-color);
  font-size: 20px;
}
.devider-icon > svg > g {
  stroke-width: 5;
}
.devider-side {
  width: 100%;
  height: 100%;
}
.devider-line {
  border-bottom: 4px solid var(--main-red-color);
}

@media only screen and (max-width: 1020px) {
  .address,
  .announce {
    font-size: 16px;
  }
}
@media only screen and (max-width: 760px) {
  .hero-container,
  .announce,
  .announce-image {
    width: 100%;
  }
  .contacts {
    width: 100%;
    padding-top: 15px;
  }
  .social {
    width: 45%;
  }
  .link {
    margin: 12px;
    width: 7vw;
    height: 7vw;
  }
  .address {
    font-size: 15px;
    width: 55%;
  }
  .announce {
    font-size: 15px;
  }
  .delivery-detiles {
    flex-direction: column;
  }
  .detiles-section {
    font-size: 16px;
    padding: 10px;
    text-align: center;
    font-weight: 700;
  }
  .coming-soon {
    letter-spacing: 8px;
    word-spacing: 30px;
    font-size: 8vw;
  }
}
@media only screen and (max-width: 464px) {
  .hero-container {
    width: 100%;
    height: 10vh;
  }
  .contacts {
    width: 100%;
    padding-top: 0;
  }
  .link {
    margin: 10px;
    width: 8.5vw;
    height: 8.5vw;
  }
  .address {
    font-size: 12px;
    width: 55%;
  }
  .social {
    width: 45%;
  }
  .menu-section,
  .product {
    width: 100%;
  }
  .delivery-header,
  .section-header {
    font-size: 28px;
  }
  .coming-soon {
    top: 42%;
    letter-spacing: 5px;
    word-spacing: 20px;
    font-size: 5vh;
  }
}
@media only screen and (max-width: 380px) {
  .address {
    width: 45%;
  }
  .email-address {
    display: none;
  }
}

.footer-contacts {
  height: 300px;
  margin: 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}
.footer {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.footer-contacts-name {
  display: inline-block;
  width: 80px;
  text-align: left;
  font-weight: 700;
}
.footer-header {
  font-family: var(--headings-font-family);
  color: var(--main-red-color);
  font-size: 30px;
  line-height: normal;
  font-weight: var(--font-weight-headings);
  margin: 0 auto;
}

.credits {
  color: var(--main-white-color);
  width: 100%;
  height: 4vh;
  background-color: var(--blured-black-color);
  font-size: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.credits > a {
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
  display: inline-block;
  margin-left: 0.5vw;
}

.flip-inner {
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
  height: 100%;
  width: 100%;
  position: relative;
}

.flipped {
  transform: rotateY(180deg);
}

.front-image,
.back-image {
  backface-visibility: hidden;
  position: absolute;
}

.front-image {
  z-index: 2;
}

.back-image {
  transform: rotateY(180deg);
}

@media only screen and (max-width: 1020px) {
  .footer {
    flex-direction: column-reverse;
    padding: 0;
  }
  .footer-contacts {
    height: fit-content;
    margin: 0;
  }
  .credits,
  .credits > a {
    font-size: 2vw;
  }
}
@media only screen and (max-width: 380px) {
  .footer-contacts {
    font-size: 14px;
  }
  .credits,
  .credits > a {
    font-size: 3vw;
  }
}
